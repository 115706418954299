
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

// banner组件通信传值设定：
// initOptions：swiper初始化相关参数
// page：getHeaderBanner的传参
// initSwiper：是否为轮播，默认非轮播
// data：自定义banner数据

@Component
export default class InsCmsPersonSwiper extends Vue {
  @Prop() private initOptions!: object;
  @Prop({ default: '' }) private page!: string;
  @Prop({ default: false }) private initSwiper!: boolean;
  @Prop() private data!: any;
  @Prop() private swiperData!: object;
  @Prop() private personIndex!: number;

  menList: object[] = [];
  manImg: string = '';

  swiperOption: object = {
    grabCursor: true,
    // autoplay: {
    //   disableOnInteraction: false
    // },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true
    // },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  };

  get swiper () {
    return (this as any).$refs.mySwiper.swiper;
  }
  stopLoop () {
    this.swiper.autoplay.stop();
  }
  closeSwiper () {
    this.$emit('showSwiper', false);
    this.swiper.activeIndex = this.personIndex;
  }
  mounted () {
    this.stopLoop();
  }
  @Watch('personIndex', { deep: true })
  onIndexChange () {
    this.swiper.slideTo(this.personIndex);
  }
}
